<script>
import Divider from 'primevue/divider';

export default {
  name: 'welcome-view',
  components: {
    Divider,
  },
  setup() {
    return {
    }
  }
}
</script>

<template>
  <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div class="text-700 text-center">
          <div class="text-green-600 font-bold mb-3"><i class="pi pi-heart"></i>&nbsp;POWERED BY YOU!</div>
          <div class="text-900 font-bold text-5xl mb-3">Get Started!</div>
          <div class="text-700 text-2xl mb-5">
            Normally your list of backed projects would be here but it looks like you're not logged in yet!
            <br/><br/>
            Interested in seeing how it works? Check out the short video tour below:
          </div>
          <iframe width="800" height="450" src="https://www.youtube.com/embed/I-9R8ZDCBWA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <br/><br/><br/>       
          <Button @click="createPledge($router)" label="Join Now" icon="pi pi-sign-in" class="primary-button font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
      </div>
  </div>

  <Divider type="dashed" />

  <div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
      <div class="mb-3 font-bold text-2xl">
          <span class="text-900">All of your backed projects, </span>
          <span class="text-green-600">One Tool</span>
      </div>
      <div class="text-700 text-sm mb-6">Manage all of your backed crowd funded projects in one easy to use tool!</div>
      <div class="grid">
          <div class="col-12 md:col-4 mb-4 px-5">
              <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                  <i class="pi pi-plus-circle text-4xl text-green-500"></i>
              </span>
              <div class="text-900 mb-3 font-medium">Quickly Add Pledges</div>
              <span class="text-700 text-sm line-height-3">Simply paste the url for a campaign and provide your pledge specific details.</span>
          </div>
          <div class="col-12 md:col-4 mb-4 px-5">
              <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                  <i class="pi pi-th-large text-4xl text-green-500"></i>
              </span>
              <div class="text-900 mb-3 font-medium">Table View or Grid View</div>
              <span class="text-700 text-sm line-height-3">View your collection in either table view or collection grid view.</span>
          </div>
          <div class="col-12 md:col-4 mb-4 px-5">
              <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                  <i class="pi pi-users text-4xl text-green-500"></i>
              </span>
              <div class="text-900 mb-3 font-medium">Shareable Profile</div>
              <span class="text-700 text-sm line-height-3">Proudly display your collection to everyone using your public profile link!</span>
          </div>
          <div class="col-12 md:col-4 mb-4 px-5">
              <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                  <i class="pi pi-wallet text-4xl text-green-500"></i>
              </span>
              <div class="text-900 mb-3 font-medium">Track various statuses</div>
              <span class="text-700 text-sm line-height-3">Track campaign status, how you're feeling, backerkit, shipped, delivered, etc.</span>
          </div>
          <div class="col-12 md:col-4 mb-4 px-5">
              <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                  <i class="pi pi-search text-4xl text-green-500"></i>
              </span>
              <div class="text-900 mb-3 font-medium">Quick Search</div>
              <span class="text-700 text-sm line-height-3">Quickly and easily search your collecton!</span>
          </div>
          <div class="col-12 md:col-4 md:mb-4 mb-0 px-3">
              <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                  <i class="pi pi-tag text-4xl text-green-500"></i>
              </span>
              <div class="text-900 mb-3 font-medium">Notes, Tags, & More</div>
              <span class="text-700 text-sm line-height-3">Track various aspects of your pledges via notes, custom tags, and more!</span>
          </div>
      </div>
  </div>
</template>

<style lang="scss" scoped>

</style>