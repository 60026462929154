import { defineStore } from 'pinia'
import axios from 'axios'
import { statusOptions, healthOptions } from '/src/constants';
import { formatDateNormal } from '@/utility/dateFormatting';
import { filter, find } from 'lodash';

const lookupStatusIcon = (mode, code) => {
  let icon = '';
  if (mode === 'status') {
    icon = find(statusOptions, { code })?.icon;
  } else if (mode === 'health') {
    icon = find(healthOptions, { code })?.icon;
  }
  
  return icon;
}

const lookupStatusLabel = (mode, code) => {
  let label = '';
  if (mode === 'status') {
    label = find(statusOptions, { code })?.label;
  } else if (mode === 'health') {
    label = find(healthOptions, { code })?.label;
  }
  
  return label;
}

export const flattenedPledges = ({ pledges }) => pledges?.map((pledge) => {
  return {
    _id: pledge._id,
    createdAt: pledge.createdAt,
    title: pledge.campaign.title,
    image: pledge.campaign.image,
    url: pledge.campaign.url,
    provider: pledge.campaign.provider,
    creator: pledge.campaign.creator,
    level: pledge.level,
    amount: pledge.amount,
    pledgemanaged: pledge.pledgemanaged,
    delivery: pledge.estdeliverydate,
    formattedDelivery: formatDateNormal(pledge.estdeliverydate),
    notes: pledge.notes,
    description: pledge.description,
    statusCode: pledge.status,
    status: lookupStatusIcon('status', pledge.status),
    statusLabel: lookupStatusLabel('status', pledge.status),
    health: lookupStatusIcon('health', pledge.health),
    healthLabel: lookupStatusLabel('health', pledge.health),
    // tags is just a single csv string from the api response:
    tags: (pledge.tags?.split(',') || []).filter((tag) => { if (tag.length > 0) return tag; }),
  };
});

export const usePledgesStore = defineStore('pledges', {
  state: () => {
    return {
      pledges: [],
      listLevel: true,
    }
  },
  getters: {
    stateListLevel: ({ listLevel }) => listLevel,
    flattenedPledges: flattenedPledges,
    pledgesList() {
      if (this.stateListLevel === null) {
        return this.activePledges;
      } else if (this.stateListLevel === true) {
        return this.flattenedPledges;
      } else if (this.stateListLevel === false) {
        return this.inactivePledges;
      }
    },
    activePledges() { 
      return filter(this.flattenedPledges, (pledge) => {
        return pledge.statusCode < 4;
      })
    },
    inactivePledges() { 
      return filter(this.flattenedPledges, (pledge) => {
        return pledge.statusCode >= 4;
      })
    },
  },
  actions: {
    initializeListLevel() {
      const persistedLevel = window.localStorage.getItem('bt-listlevel');
      if (persistedLevel === 'true') {
        this.listLevel = true;
      } else if (persistedLevel === 'false') {
        this.listLevel = false;
      } else if (persistedLevel === 'null') {
        this.listLevel = null;
      }
    },
    setPledgesListLevel(level) {
      this.listLevel = level;
      window.localStorage.setItem('bt-listlevel', level);
    },
    getUserPledges() {
      axios.get(`${process.env.VUE_APP_HOST}/api/pledges/mine`).then(({ data }) => {
        this.pledges = data;
      }).catch(e => {});      
    },

    async getUserPledgesByUsername(username) {
      return await axios.get(`${process.env.VUE_APP_HOST}/api/pledges/${username}`);
    },

    async createCampaign(campaign) {
      return await axios.post(`${process.env.VUE_APP_HOST}/api/campaigns`, campaign);
    },

    async refreshCampaignImage(url) {
      return await axios.get(`${process.env.VUE_APP_HOST}/api/campaigns/refresh_image?url=${url}`);
    },

    async createUserPledge(pledge) {
      await axios.post(`${process.env.VUE_APP_HOST}/api/pledges`, pledge);
      this.getUserPledges();
    },

    campaignLookup(url, fresh = false) {
      return axios.get(`${process.env.VUE_APP_HOST}/api/campaigns/scrape?url=${url}${fresh ? '&fresh=true' : ''}`);
    },

    async editUserPledge(pledge) {
      await axios.put(`${process.env.VUE_APP_HOST}/api/pledges`, pledge);
      this.getUserPledges();
    },

    async updatePledgeStatus(payload) {
      await axios.put(`${process.env.VUE_APP_HOST}/api/pledges/status`, payload);
      this.getUserPledges();
    }
  },

})