import { defineStore } from 'pinia'
import axios from 'axios'
import { jwtInterceptor, clearJwtInterceptor } from '@/services/jwt.interceptor'
import { useCookies } from "vue3-cookies"

const { cookies } = useCookies()

export const useUserStore = defineStore('user', {
  state: () => ({
    isLoggedIn: false,
    user: null,
  }),
  actions: {
    async getUserProfile() {
      const jwtToken = cookies.get('access_token')
      if (jwtToken) {        
        try {
          const response = await axios.get(`${process.env.VUE_APP_HOST}/api/users/profile`, {
            headers: {
              common: {
                authorization: `Bearer ${jwtToken}`,
              },
            },
          })
          this.user = response.data
          this.isLoggedIn = true
          jwtInterceptor(jwtToken)
        } catch (error) {
          cookies.remove('access_token')
          return null
        }
      }
    },
    async getUserPublicProfile(username) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_HOST}/api/users/profile/${username}`)
        return response.data
      } catch (error) {
        return false
      }
    },
    logoutUser() {
      cookies.remove('access_token')
      clearJwtInterceptor()
      this.isLoggedIn = false
      this.user = null
    },
    async saveProfile(profile) {
      try {
        await axios.put(`${process.env.VUE_APP_HOST}/api/users/profile`, profile);
        Object.assign(this.user, profile);
      } catch(e) {
        return false;
      }
    }
  },
})