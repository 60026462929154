<script>
import { computed, reactive, ref, watch } from 'vue'
import { useUserStore } from '@/stores/user.store';
import { usePledgesStore } from './stores/pledges.store';
import { useAppStore } from '@/stores/app.store';
import Card from 'primevue/card';
import Divider from 'primevue/divider';
import TabMenu from 'primevue/tabmenu';
import Dialog from 'primevue/dialog';

export default {
  components: {
    Card,
    Dialog,
    Divider,
    TabMenu,
  },
  setup() {
    const userStore = useUserStore();
    const pledgesStore = usePledgesStore();
    const appStore = useAppStore();
    const navItems = reactive([]);
    const google_auth = `${process.env.VUE_APP_HOST}/api/auth/google`
    
    pledgesStore.initializeListLevel();

    const setupNavItems = () => {
      navItems.splice(0);
      navItems.push(...[
        {label: 'Home', icon: 'pi pi-fw pi-home', to: '/'},
        {label: 'About', icon: 'pi pi-fw pi-file', to: '/about'},
      ]);

      if (userStore.isLoggedIn) {        
        navItems.push({label: 'Your Profile', icon: 'pi pi-fw pi-id-card', to: `/users/${userStore.user.username || 'profile'}`});
      }
    }
     
    setupNavItems();
    userStore.getUserProfile();

    const showLogin = () => {
      appStore.showLoginDialog = true;
    }

    const logout = () => {
      userStore.logoutUser();
    }

    watch(
      () => userStore.isLoggedIn,
      () => {
        setupNavItems();
      }
    );

    watch(
      () => userStore?.user?.username,
      () => {
        setupNavItems();
      }
    );

    return {
      navItems,
      showLogin,
      google_auth,
      appStore,
      isLoggedIn: computed(() => userStore.isLoggedIn),
      avatar: computed(() => userStore.user?.avatar),
      logout,
    };
  }
}
</script>

<template>
  <Toast />
  
  <Card>
    <template #title>
      <div class="grid">
        <div class="col-6">
          <i class="pi pi-box logo mr-2"></i> <span class="logo-title">bactrac<span class="dot">.</span><span class="io">io</span></span><br/>
          <span style="font-size: .85em; font-weight: normal;">Keep track of your crowd funded projects!</span>
        </div>
        <div class="col-6" style="text-align: right;">
          <Button v-if="isLoggedIn" class="p-button-text login-button" @click="logout">
            <i class="pi pi-sign-out mr-2"></i> Logout
            <div :style="{ 'background-image': `url(${avatar})` }" class="logout-avatar"></div>
          </Button>
          <Button icon="pi pi-sign-in" v-else label="Login / Join" class="p-button-text login-button" @click="showLogin" />
        </div>
      </div>
    </template>
    <template #content>
      <TabMenu :model="navItems" />
      <router-view />      
    </template>
    <template #footer>
      <Divider align="right">
        <div class="inline-flex align-items-center footer-logo">
            <i class="pi pi-box mr-2"></i>
            bactrac<span class="dot">.</span><span class="io">io</span>
        </div>
      </Divider>
      
      <a href="#" target="_blank"><i class="pi pi-discord mr-3" style="color: var(--gray-500); font-size: 1.5em;"></i></a>
      <a href="#" target="_blank"><i class="pi pi-twitter mr-3" style="color: var(--gray-500); font-size: 1.5em;"></i></a>
      <a href="#" target="_blank"><i class="pi pi-instagram mr-3" style="color: var(--gray-500); font-size: 1.5em;"></i></a>
      <a href="#" target="_blank"><i class="pi pi-youtube mr-3" style="color: var(--gray-500); font-size: 1.5em;"></i></a>
      
    </template>
  </Card>

  <Dialog v-model:visible="appStore.showLoginDialog" :style="{width: '30vw'}" :modal="true">
    <template #header>
      <h3>Login / Join</h3>
    </template>
    <p>
      Join using any of the options below! An account is required in order to use this service.
    </p>
    <br/>
    <a :href="google_auth" class="p-button p-button-outlined p-button-secondary login-button google">
      <i class="pi pi-google mr-2" style="font-size: 2em;"></i>
      Join & Login via Google
    </a>
    <a href="#" class="p-button p-button-outlined p-button-secondary login-button facebook" style="opacity: .5;">
      <i class="pi pi-facebook mr-2" style="font-size: 2em;"></i>
      Join & Login via Facebook<br/>
      <span style="margin-left: 40px; font-size: 12px;">Coming Soon!</span>
    </a>
    <a href="#" class="p-button p-button-outlined p-button-secondary login-button twitter" style="opacity: .5;">
      <i class="pi pi-twitter mr-2" style="font-size: 2em;"></i>
      Join & Login via Twitter<br/>
      <span style="margin-left: 40px; font-size: 12px;">Coming Soon!</span>
    </a>
    <template #footer></template>
  </Dialog>
</template>

<style lang="scss">
html {
  font-size: 15px;
}
body {
  background-color: #c1c1c1;
}
:root {
  --primary-color:#22c55e!important;
  --focus-ring: 0 0 0 1px #22c55e!important;
}

.logout-avatar {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin-left: .5em;
  background-size: 60px;
  background-position: center;
  background-repeat: no-repeat;
}

.logo {
  font-size: 2em!important;
  margin-bottom: .25em;
}
.logo-title {
  font-family: 'Do Hyeon', sans-serif;
  font-size: 2em;
  span.io {
    color: var(--green-500);
  }
  span.dot {
    color: var(--bluegray-100);
  }
}
.footer-logo {
  font-family: 'Do Hyeon', sans-serif;
  font-size: 1.5em;
  span.io {
    color: var(--green-500);
  }
  span.dot {
    color: var(--bluegray-100);
  }
}
a {
  color: var(--primary-color);
  text-decoration: none;
}
.separator {
  margin: 2em;
}
.p-divider-solid.p-divider-horizontal:before {
  border-top-style: solid !important;
}
.p-divider-dashed.p-divider-horizontal:before {
  border-top-style: dashed !important;
}
.p-divider-solid.p-divider-vertical:before {
  border-left-style: solid !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:focus {
  box-shadow: none!important;
}

.p-button:focus {
  box-shadow: none!important;
}

.pi.pi-shopping-bag.logo {
  font-size: 1em;
  vertical-align: baseline;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none!important;
}

button.login-button {
  &:hover {
    background: rgba(70, 229, 83, 0.034)!important;
  }
  &:active {
    background: rgb(101 229 70 / 16%)!important;
  }
}

a.login-button {
  text-align: left;
  text-decoration: none;
  display: block;
  margin-bottom: .5em;
  &.google i {
    color: #e94235;
  }
}

.p-chip.tag {
  margin-top: .5em;
  background-color: transparent;
  padding: 0 0.5rem;
  &:first-of-type { 
    margin-left: 30px;
  }
  .p-chip-text {
    line-height: 6px!important;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 10px;
  }
}

// global color overrides:
.login-button {
  color: black!important;
}
.primary-button {
  background-color: var(--green-500)!important;
  border: solid 1px var(--green-500)!important;
  &:hover {
    background-color: var(--green-600)!important;
  }
  &:active {
    background-color: var(--green-400)!important;
  }
}
.p-button.p-button-text:enabled:hover {
  background: rgb(70 229 82 / 8%);
}
.p-inputtext:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--green-500)!important;
  box-shadow: 0 0 0 1px var(--green-200)!important;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link,
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight  {
  border-color: var(--green-500)!important;
}
.p-selectbutton .p-button.p-highlight {
  background-color: var(--gray-500)!important;
  border-color: var(--gray-600)!important;
}
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: var(--green-500)!important;
}
// pagniator:
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #2e8134!important;
  background: #eeffee!important;
}
.p-link:focus {
  box-shadow: 0 0 0 1px var(--green-200)!important;
}

</style>
