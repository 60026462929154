<script>
import { computed, reactive, watch } from 'vue';
import { stringToRgbColor } from '../../utility/stringToColor';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Chip from 'primevue/chip';
import { formatDateShort, formatDateNormal, formatDateShortDay } from '@/utility/dateFormatting';
import { FilterMatchMode } from 'primevue/api';
import formatCurrency from '@/utility/formatCurrency';
import ListingToggle from './listing-toggle.vue';

export default {
  components: {
    DataTable,
    Column,
    Chip,
    ListingToggle,
  },
  props: {
    pledges: Array,
    filter: String,
  },
  emits: ['select', 'pledgemanaged', 'statusEdit', 'healthEdit'],
  setup(props) {
    const rowClass = (data) => {
      if (data.status === '📦' || data.status === '⛔️' || data.status === '❌') {
        return 'archived';
      }
      return null;
    };

    const searchFilters = reactive({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
    });

    const sortOptions = reactive({
      sortField: 'createdAt',
      sortOrder: -1,
      rows: 10,
    });

    try {
      Object.assign(sortOptions, JSON.parse(window.localStorage.getItem('bt-sort')));
    } catch(e) {
      console.log(e);
    }

    const onSorted = (options) => {
      const { sortField, sortOrder, rows } = options;
      window.localStorage.setItem('bt-sort', JSON.stringify({ sortField, sortOrder, rows }));
    }

    const resetSort = () => {
      window.localStorage.removeItem('bt-sort');
      sortOptions.sortField = 'createdAt';
      sortOptions.sortOrder = -1;
    }

    const totalPledged = computed(() => {
      return props.pledges.reduce((prevVal, currentVal) => {
        return prevVal + currentVal.amount;
      }, 0);
    })

    watch(
      () => props.filter,
      (newVal) => {
        searchFilters['global'].value = newVal;
      }
    );

    return {
      rowClass,
      formatDateShort,
      formatDateShortDay,
      formatDateNormal,
      formatCurrency,
      stringToRgbColor,
      onSorted,
      sortOptions,
      searchFilters,
      resetSort,
      totalPledged,
    };
  }
}
</script>

<template>
  <DataTable  :value="pledges" showGridlines responsiveLayout="scroll" class="border-round"
    :paginator="true" :rows="sortOptions.rows" :rowsPerPageOptions="[20,50,75]" :rowClass="rowClass" @sort="onSorted" @page="onSorted" :sortField="sortOptions.sortField" :sortOrder="sortOptions.sortOrder"
    v-model:filters="searchFilters" :globalFilterFields="['title', 'creator', 'level', 'tags']"
    dataKey="_id"
    >
      <Column field="createdAt" header="Added" :sortable="true" :hidden="true"></Column>
      <Column field="title" header="Campaign" :sortable="true" style="max-width: 400px; white-space: nowrap; text-overflow: ellipsis;">
        <template #body="slotProps">
          <img :src="`img/${slotProps.data.provider}.png`" class="provider" />
          <a :href="slotProps.data.url" class="font-semibold --primary-color campaign-link" target="_blank">{{ slotProps.data.title }}</a>
          <div v-if="slotProps.data.tags?.length > 0">
            <Chip v-for="tag in slotProps.data.tags" :label="tag" :key="tag" class="mr-2 mb-2 tag" :style="{ 'font-size': '.8em', 'border': `${stringToRgbColor(tag, '1')} solid 2px`, color: stringToRgbColor(tag, '1') }" />
          </div>
        </template>
      </Column>
      <Column field="creator" header="Creator" :sortable="true"></Column>
      <Column field="level" header="Pledge Level">
        <template #body="slotProps">          
          {{ slotProps.data.level }}<i v-if="slotProps.data.notes?.length > 0" class="pi pi-info-circle dialog-icon mr-2" @click="$emit('select', slotProps.data._id)" v-badge.info></i><i v-else class="pi pi-info-circle dialog-icon" @click="$emit('select', slotProps.data._id)"></i>
          <br/>
          <span class="createdAt">{{ formatDateShortDay(slotProps.data.createdAt) }}</span>
        </template>
      </Column>
      <Column field="amount" header="Amount" :sortable="true">
        <template #body="slotProps">
          <Chip :label="`${formatCurrency(slotProps.data.amount)}`" class="mr-2 mb-2 amount" style="font-size: .9em;"/>
        </template>
      </Column>
      <Column field="delivery" header="Est. Delivery" :sortable="true">
        <template #body="slotProps">
          {{ formatDateShort(slotProps.data.delivery) }}
        </template>
      </Column>
      <Column field="health" header="" style="width: 175px;" headerClass="reset-sort">
      <template #header>
        <div style="text-align: right; width: 100%;"><a @click="resetSort()" class="reset-sort-btn" title="Reset table sort."><i class="pi pi-sort-alt-slash"></i></a></div>
      </template>
        <template #body="slotProps">
          <span style="font-size: 1.5em;">
            <a @click="$emit('statusEdit', $event, slotProps.data._id)" class="status-icon" v-tooltip.top="{ value: slotProps.data.statusLabel }">{{ slotProps.data.status }}</a>
            <a @click="$emit('healthEdit', $event, slotProps.data._id)" class="status-icon" v-tooltip.top="{ value: slotProps.data.healthLabel }">{{ slotProps.data.health }}</a> 
            <a @click="$emit('pledgemanaged', slotProps.data._id, slotProps.data.pledgemanaged)" class="status-icon" v-tooltip.top="{ value: 'Completed Pledgemanager?' }">{{ slotProps.data.pledgemanaged ? '✅' : '🔳' }}</a>
          </span>
        </template>
      </Column>
      <template #footer>
        <ListingToggle v-if="pledges" />

        Total pledges: {{ pledges.length }} | {{ formatCurrency(totalPledged) }}        
      </template>
      <template #empty>
        <h2>No pledges found.</h2>
        Get started by adding your first campaign you've pledged!
      </template>
  </DataTable>
</template>

<style lang="scss" scoped>
table {
  width: 100%;
}
thead td {
  font-weight: bold;
}
.provider {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  margin-right: 0.5em;
  border-radius: 20px;
  opacity: .75;
  margin-top: -7px;
}

.createdAt {
  font-size: .8em;
  color: #a0a0a0;
}

.campaign-link {
  display: inline-block;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;;
}

.dialog-icon {
  cursor: pointer;
  margin-right: 0.25em;
  margin-left: 0.25em;
}

.status-icon {
  cursor: pointer;
  margin-left: .35em;
  margin-right: .35em;
}

.reset-sort {
  text-align: right!important;
}

.reset-sort-btn {
  color: #878787;
  cursor: pointer;
}



</style>