<script>
export default {
  name: 'about-view',
}
</script>

<template>
  <h2>Why bactrac?!</h2>
  <p>I'm going to be honest.  I back a lot of projects on Kickstarter and Gamefound.  I'm a <span class="superbacker">Superbacker</span>!</p>
  <p>It got to the point where I was using a fairly complicated Excel document to track all of the projects I've backed, their status, notes, links, etc.  It was becoming a pain in the butt.</p>
  <p>I've always been a web engineer, and the past year or so I've been doing mostly management and missed coding.  I wanted a project I could throw together quickly that would also actually be useful.</p>
  <p>So, here we are!</p>
  <h2>So what is it?</h2>
  <p>Simply put, this is a simple web application you can use to track the various crowd funded projects you back.</p> 
    <ul>
      <li>Paste the url to the project...</li>
      <li>we scrape as much info as we can</li>
      <li>and then you provide your pledge level specifcs</li> 
    </ul>
  <p>Thats it!  There are various sorting options, a personal profile, and some other useful tools as well!</p>
  <p>It's a work in progress and a labor of love, so it will constantly be evolving and getting new features over time!</p>
  <h3>I hope you find this tool useful and enjoy using it as much as I enjoyed making it!</h3>
</template>

<style lang="scss" scoped>
.superbacker {
  background-color: #037362;
  color: #ffffff;
  display: inline-block;
  padding: 4px 5px;
  font-size: 12px;
  line-height: 1.1em;
  font-weight: normal;
}
</style>