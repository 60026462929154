<script>
import { ref } from 'vue';
import Button from 'primevue/button';
import { statusOptions, healthOptions } from '/src/constants';

export default {
  components:  {
    Button,
  },
  props: {
    mode: String,
  },
  emit: ['selected'],
  setup(props) {
    const op = ref();
    const status = ref(0);
    const health = ref(0);
    const optionsList = [];

    if (props.mode === 'status') {
      optionsList.push(...statusOptions);
    } else if (props.mode === 'health') {
      optionsList.push(...healthOptions);
    }

    const toggle = (event) => {
      op.value.toggle(event);
    }

    return {
      op,
      optionsList,
      toggle,
    };
  }
}
</script>

<template>
  <ul>
    <li v-for="item in optionsList" :key="item.code">        
      <Button class="p-button-rounded p-button-text button" @click="$emit('selected', item.code)">{{ item.icon }} &nbsp; {{ item.label }}</Button>
    </li>
  </ul>
</template>

<style scoped lang="scss">
ul {
  padding: 0 1em;
  margin: 0;
}
li {
  list-style: none;
  font-size: 1.15em;
}
.p-overlaypanel-content {
  padding: .25em!important;
}

.button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 4px!important;
  &:focus {
    box-shadow: none;
  }
  padding: 0;
}
</style>