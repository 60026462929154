<script>
import { computed, watch, onMounted, ref, reactive } from 'vue';
import { useUserStore } from '@/stores/user.store';
import { usePledgesStore } from '@/stores/pledges.store';
import { useToast } from 'primevue/usetoast';
import list from '../components/list.vue';
import grid from '../components/grid.vue';
import empty from '../components/empty.vue';
import quickEdit from '../../listing/components/quickedit.vue'
import pledgeDetails from '../../pledge/components/details.vue'
import createPledge from '../../pledge/components/create.vue';
import OverlayPanel from 'primevue/overlaypanel';
import SelectButton from 'primevue/selectbutton';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import { find } from 'lodash';
import { debounce } from 'lodash';

export default {
  name: 'listing-view',
  components: {
    grid,
    list,
    empty,
    pledgeDetails,
    createPledge,
    OverlayPanel,
    Dialog,
    SelectButton,
    InputText,
    quickEdit,
  },
  setup() {
    const toast = useToast();
    const userStore = useUserStore();
    const pledgesStore = usePledgesStore();
    const isLoggedIn = computed(() => userStore.isLoggedIn);
    const viewMode = ref(window.localStorage.getItem('bt-viewmode') || 'list');
    const viewModeOptions = ref([
      {icon: 'pi pi-list', value: 'list'},
      {icon: 'pi pi-th-large', value: 'grid'},
    ]);
    const displayAddPledgeDialog = ref(false);
    const displayPledgeDialog = ref(false);
    const activePledge = reactive({
      pledgeDetails: {},
    });

    const persistViewMode = ({ value }) => {
      window.localStorage.setItem('bt-viewmode', value);
    }

    const selectPledge = (id) => {
      activePledge.pledgeDetails = {...find(pledgesStore.flattenedPledges, { _id: id }) };
      displayPledgeDialog.value = true;
    }

    const searchInput = ref('');
    const handleSearchInput = debounce(($event) => {
      searchInput.value = $event.target.value === '' ? null : $event.target.value;
    }, 500);

    onMounted(() => {
      if (isLoggedIn.value) {
        pledgesStore.getUserPledges();
      }
    })

    watch(
      () => userStore.isLoggedIn,
      (isLoggedIn) => {
        if (isLoggedIn) {
          pledgesStore.getUserPledges();
        }
      }
    );

    const quickeditOp = ref();
    const quickeditMode = ref('status');
    const quickeditId = ref('');
    const toggleStatusQuickedit = (event, id) => {
      quickeditMode.value = 'status';
      quickeditId.value = id;
      quickeditOp.value.toggle(event);
    }
    const toggleHealthQuickedit = (event, id) => {
      quickeditMode.value = 'health';
      quickeditId.value = id;
      quickeditOp.value.toggle(event);
    }

    const quickEditSelection = async (val) => {
      quickeditOp.value.toggle(event);
      const payload = {
        id: quickeditId.value,
      };
      if (quickeditMode.value === 'status') {
        payload.status = val;
      } else if (quickeditMode.value === 'health') {
        payload.health = val;
      }

      try {
        await pledgesStore.updatePledgeStatus(payload);
        toast.add({severity:'success', summary: 'Updated!', detail: 'The pledge\'s status has been updated.', life: 3000});
      } catch (e) {
        toast.add({severity:'danger', summary: 'Error!', detail: 'There was an error updating the pledge\'s status.', life: 3000});
      }
    }

    const togglePledgeManaged = async (id, pledgemanaged) => {
      const payload = {
        id,
        pledgemanaged: !pledgemanaged
      }

      try {
        await pledgesStore.updatePledgeStatus(payload);
        toast.add({severity:'success', summary: 'Updated!', detail: 'The pledge\'s status has been updated.', life: 3000});
      } catch (e) {
        toast.add({severity:'danger', summary: 'Error!', detail: 'There was an error updating the pledge\'s status.', life: 3000});
      }
    }

    const editPledge = ref(false);
    const resetActivePledge = () => {
      activePledge.pledgeDetails = {};
      editPledge.value = false;
    }

    return {
      pledgesStore,
      activePledge,
      isLoggedIn,
      createPledge,
      viewMode,
      viewModeOptions,
      persistViewMode,
      selectPledge,
      displayAddPledgeDialog,
      displayPledgeDialog,
      editPledge,
      resetActivePledge,
      quickeditOp,
      quickeditMode,
      quickEditSelection,
      toggleHealthQuickedit,
      toggleStatusQuickedit,
      togglePledgeManaged,
      searchInput,
      handleSearchInput,
    }
  }
}
</script>

<template>

  <div class="flex">
    <div class="flex-1 flex align-items-left justify-content-left">
      <h2>Your Pledges:</h2>
    </div>
    <div class="flex-1 justify-content-end flex align-items-center justify-content-right">
    
      <span class="p-input-icon-left" v-if="viewMode === 'list'">
        <i class="pi pi-search" />
        <InputText @keyup="handleSearchInput" placeholder="Search" class="search-input" />
      </span>
      &nbsp;
      <Button @click="displayAddPledgeDialog = true" v-if="isLoggedIn" style="" class="p-button-text p-button-success add-pledge" label="Add Pledge" icon="pi pi-plus-circle" /> 

      <SelectButton class="view-mode" v-model="viewMode" @change="persistViewMode" optionValue="value" :options="viewModeOptions" dataKey="value">
        <template #option="slotProps">
          <i :class="slotProps.option.icon"></i>
        </template>
      </SelectButton>
    </div>
  </div>

  <list v-if="pledgesStore.pledges && viewMode === 'list'" :pledges="pledgesStore.pledgesList" 
    @select="selectPledge" 
    @statusEdit="toggleStatusQuickedit"
    @healthEdit="toggleHealthQuickedit"
    @pledgemanaged="togglePledgeManaged"
    :filter="searchInput"
  />
  <grid v-else-if="pledgesStore.pledges && viewMode === 'grid'" :pledges="pledgesStore.pledgesList" 
    @select="selectPledge"
    @statusEdit="toggleStatusQuickedit"
    @healthEdit="toggleHealthQuickedit"
    @pledgemanaged="togglePledgeManaged" 
    :filter="searchInput" />
  <empty v-else />

  <Dialog header="Add a Pledge" v-model:visible="displayAddPledgeDialog" :style="{width: '60vw'}" :modal="true">
    <create-pledge />
    <template #footer></template>
  </Dialog>

  <Dialog :header="activePledge.pledgeDetails.title" v-model:visible="displayPledgeDialog" :style="{width: '40vw'}" @hide="resetActivePledge" :modal="true">
    <pledge-details :pledge="activePledge.pledgeDetails" :mode="editPledge ? 'edit' : 'details'" @cancel="displayPledgeDialog = false" />
    <template #footer></template>
  </Dialog>

  <OverlayPanel ref="quickeditOp" appendTo="body" :showCloseIcon="false" :dismissable="true" id="overlay_panel" style="min-width: 320px;">
    <quick-edit :mode="quickeditMode" @selected="quickEditSelection" />
  </OverlayPanel>
    
</template>

<style lang="scss" scoped>
.add-pledge {
  margin-right: 1em;
}
.search-input {
  width: 350px;
}
</style>