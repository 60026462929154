<script>
import { computed, reactive, watch } from 'vue';
import DataView from 'primevue/dataview';
import Chip from 'primevue/chip';
import ListingToggle from './listing-toggle.vue';
import dayjs from 'dayjs';
import formatCurrency from '@/utility/formatCurrency';

export default {
  props: {
    pledges: Array,
    mode: String,
    filter: String,
  },
  components: {
    DataView,
    Chip,
    ListingToggle,
  },
  emits: ['select', 'pledgemanaged', 'statusEdit', 'healthEdit'],
  setup(props) {
    const formatDeliveryDate = (dateStr) => {
      return dateStr ? dayjs(dateStr).format('MMM, YYYY') : '???';
    };

    const sortOptions = reactive({
      sortField: 'createdAt',
      sortOrder: -1,
      rows: 20,
    });
    
    try {
      Object.assign(sortOptions, JSON.parse(window.localStorage.getItem('bt-sort')));
    } catch(e) {
      console.log(e);
    }

    const totalPledged = computed(() => {
      return props.pledges.reduce((prevVal, currentVal) => {
        return prevVal + currentVal.amount;
      }, 0);
    })

    watch(
      () => props.filter,
      (newVal) => {
        console.log(newVal);
        
        // TODO: seems like dataView doesnt support filters the way dataTable does, so we
        // might have to manually just create a filter that updates the source collection
        // based on keyword search terms :( 
      }
    );

    return {
      formatDeliveryDate,
      formatCurrency,
      sortOptions,
      totalPledged,
    }
  }
}
</script>

<template>
  <DataView :value="pledges" layout="grid" :paginator="true" :rows="sortOptions.rows" :sortField="sortOptions.sortField" :sortOrder="sortOptions.sortOrder">
    <template #grid="slotProps">
      <div class="col-12 md:col-3" :style="{ opacity: slotProps.data.status === 4 ? '0.5' : '1' }">
        <div class="product-grid-item card border-round">
          <img :src="slotProps.data.image" style="width: 90%;" :key="slotProps.data.image" />
          <div class="content">
            <a :href="slotProps.data.url" target="_blank" class="font-weight-500 campaign-link">{{ slotProps.data.title }}</a>
            <div class="pledge-level">
              {{ slotProps.data.level }}
              <span v-if="mode !== 'public'">
                <i v-if="slotProps.data.notes?.length > 0" class="pi pi-info-circle dialog-icon mr-2" @click="$emit('select', slotProps.data._id)" v-badge.info></i><i v-else class="pi pi-info-circle dialog-icon" @click="$emit('select', slotProps.data._id)"></i>
              </span>
            </div>
            <div style="text-align: right; margin-right: 1em;">
              <Chip v-if="mode !== 'public'" :label="`${formatCurrency(slotProps.data.amount)}`" class="mr-2 mb-2 amount" style="font-size: .9em;"/>
              {{ slotProps.data.delivery ? formatDeliveryDate(slotProps.data.formattedDelivery) : '???' }}
              &nbsp;
              <span v-if="mode !== 'public'">
                <a @click="$emit('statusEdit', $event, slotProps.data._id)" class="status-icon" v-tooltip.top="{ value: slotProps.data.statusLabel }">{{ slotProps.data.status }}</a> &nbsp;
                <a @click="$emit('healthEdit', $event, slotProps.data._id)" class="status-icon" v-tooltip.top="{ value: slotProps.data.healthLabel }">{{ slotProps.data.health }}</a> &nbsp;
                <a @click="$emit('pledgemanaged', slotProps.data._id, slotProps.data.pledgemanaged)" class="status-icon" v-tooltip.top="{ value: 'Completed Pledgemanager?' }">{{ slotProps.data.pledgemanaged ? '✅' : '🔳' }}</a>
              </span>
              <span v-else>
                {{ slotProps.data.status }} &nbsp; 
                {{ slotProps.data.health }} &nbsp; 
                {{ slotProps.data.pledgemanaged ? '✅' : '🔳' }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
     <template #footer>
        <ListingToggle v-if="pledges" />
        Total pledges: {{ pledges.length }} <span v-if="mode !== 'public'">| {{ formatCurrency(totalPledged) }}</span>
      </template>
    <template #empty>
      <h2>No pledges found.</h2>
      <span v-if="mode !== 'public'">Get started by adding your first campaign you've pledged!</span>
      <span v-else>This user hasn't added any pledges yet!</span>
    </template>
  </DataView>
</template>

<style lang="scss" scoped>
.dialog-icon {
  cursor: pointer;
  margin-right: 0.25em;
  margin-left: 0.25em;
}

.status-icon {
  cursor: pointer;
  margin-left: .10em;
  margin-right: .10em;
}

.campaign-link {
  color: black;
}

::v-deep(.product-grid-item) {
	margin: .5rem;
	border: 1px solid var(--surface-border);
  min-height: 340px;
  img {
		width: 95%;
    display: block;
    margin: 1em auto;
	}
  .content {
    width: 93%;
    margin: 0.5em 1.5em;
    a {
      font-weight: 500;
      font-size: 1.15em;
    }
    .pledge-level {
      margin: .5em 0 1em 0;
    }
  }
}
</style>