<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user.store';
import { usePledgesStore } from '@/stores/pledges.store';
import { debounce } from 'lodash';
import { statusOptions, healthOptions, providerOptions } from '/src/constants';
// primevue:
import { useToast } from 'primevue/usetoast';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Fieldset from 'primevue/fieldset';
import Divider from 'primevue/divider';
import Textarea from 'primevue/textarea';

export default {
  name: 'create-view',
  components: {
    Dropdown,
    Divider,
    InputText,
    Fieldset,
    Textarea,
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    if (userStore.isLoggedIn !== true) {
      router.push({ name: 'listing-view' });
    }
    const toast = useToast();
    const pledgesStore = usePledgesStore();
    
    const campaignId = ref('');
    const title = ref('');
    const creator = ref('');
    const url = ref('');
    const provider = ref('');
    const providers = [...providerOptions];
    const startdate = ref('');
    const enddate = ref('');
    const level = ref('');
    const description = ref('');
    const image = ref('');
    const amount = ref('');
    const note = ref('');
    const delivery = ref('');
    const status = ref(0);
    const health = ref(0);
    const statusOptionsDD = statusOptions.map(option => ({ code: option.code, label: `${option.icon}  ${option.label}` }));
    const healthOptionsDD = healthOptions.map(option => ({ code: option.code, label: `${option.icon}  ${option.label}` }));

    // viewState:
    const disableInputs = ref(false);
    const disableAddlInputs = ref(false);
    const isLoading = ref(false);
    const fetchingMeta = ref(false);
    const fetchingFailed = ref(false);
    const fetchingSucceeded = ref(false);

    const resetFormFields = () => {
      campaignId.value = '';
      title.value = '';
      creator.value = '';
      url.value = '';
      provider.value = '';
      startdate.value = '';
      enddate.value = '';
      level.value = '';
      description.value = '';
      image.value = '';
      amount.value = '';
      note.value = '';
      delivery.value = '';
      status.value = 0;
      health.value = 0;
      
      disableInputs.value = false;
      isLoading.value = false;
      fetchingMeta.value = false;
      fetchingFailed.value = false;
      fetchingSucceeded.value = false;
    }

    const createPledge = async () => {
      if (title.value.length && level.value.length && amount.value.length) {
        isLoading.value = true;
        try {
          // create the campaign
          let campaignResults;
          if (!campaignId.value) {
            campaignResults = await pledgesStore.createCampaign({
              title: title.value,
              creator: creator.value,
              url: url.value,
              image: image.value,
              provider: provider.value,
              startdate: startdate.value,
              enddate: enddate.value,
            });
          }

          // create a pledge and assign the campaign id
          const pledgePayload = {
            campaign: campaignId.value || campaignResults.data._id,
            level: level.value,
            amount: amount.value,
            description: description.value,
            estdeliverydate: delivery.value,
            notes: note.value,
            status: status.value,
            health: health.value,
          };
          await pledgesStore.createUserPledge(pledgePayload);
          toast.add({severity:'success', summary: 'Created!', detail: 'Your pledge has been added.', life: 3000});
          resetFormFields();
        } catch(e) {
          toast.add({severity:'error', summary: 'Error!', detail: e, life: 3000});
        }
        isLoading.value = false;
      } else {
        toast.add({severity:'error', summary: 'Required!', detail: 'Please complete all required fields.', life: 3000});
      }
    }

    const campaignLookup = debounce(() => {
      if (url.value.length > 3 && url.value.substring(0,4).toLowerCase() === 'http') {
        disableInputs.value = false;
        disableAddlInputs.value = false;
        fetchingMeta.value = true;
        fetchingFailed.value = false;        
        fetchingSucceeded.value = false;
        pledgesStore.campaignLookup(url.value)
          .then(({ data }) => {
            url.value = data.url;
            title.value = data.title;
            provider.value = data.provider?.toLowerCase();
            creator.value = data.author;
            image.value = data.image;
            if (data._id) {
              campaignId.value = data._id;
              creator.value = data.creator;
              startdate.value = data.startdate;
              enddate.value = data.enddate;
              disableAddlInputs.value = true;
            } else {
              campaignId.value = '';
            }
            fetchingSucceeded.value = true;
            disableInputs.value = true;
          }).catch((e) => {
            console.log(e);
            fetchingFailed.value = true;
          })
          .finally(() => {
            fetchingMeta.value = false;
          });
      }          
    }, 350);

    return {
      disableInputs,
      disableAddlInputs,
      isLoading,
      fetchingMeta,
      fetchingFailed,
      fetchingSucceeded,
      // campaign:
      title,
      creator,
      url,
      provider,
      providers,
      startdate,
      enddate,
      // pledge:
      level,
      amount,
      description,
      note,
      delivery,
      status,
      statusOptionsDD,
      health,
      healthOptionsDD,
      // methods:
      resetFormFields,
      createPledge,
      campaignLookup,
    };
  }
}
</script>

<template> 
  <Fieldset legend="Campaign Details">
    <div class="grid">
      <div class="col-2">    
        <label for="url" class="required">Url:</label>
      </div>
      <div class="col-10">    
        <InputText id="url" type="text" v-model="url" @keyup="campaignLookup" class="inputfield w-11" /> 
        &nbsp;
        <i v-if="fetchingMeta" class="pi pi-spin pi-sync"></i>
        <i v-if="fetchingFailed" class="pi pi-exclamation-triangle" style="color: #ffaf66;"></i>
        <i v-if="fetchingSucceeded" class="pi pi-check-circle" style="color: green;"></i>
      </div>
    </div>
    <div class="grid">
      <div class="col-2">    
        <label for="title" class="required">Title:</label>
      </div>
      <div class="col-10">
        <InputText id="title" type="text" v-model="title" class="inputfield w-8" :disabled="disableInputs" />
      </div>
    </div>
    <div class="grid">
      <div class="col-2">    
        <label for="provider">Provider:</label>
      </div>
      <div class="col-10">
        <Dropdown v-model="provider" :options="providers" :disabled="disableInputs" optionValue="code" optionLabel="name" placeholder="Select provider" class="inputfield w-4" />
      </div>
    </div>
    <Divider type="dashed"/>
    <div class="grid">
      <div class="col-2">    
        <label for="creator">Creator:</label>
      </div>
      <div class="col-10">
         <InputText id="creator" type="text" v-model="creator" class="inputfield w-6" :disabled="disableAddlInputs" /> 
      </div>
    </div>
    <div class="grid">
      <div class="col-2">    
        <label for="startdate" class="date">Start Date:</label> 
      </div>
      <div class="col-10">
        <InputText id="startdate" type="date" v-model="startdate" class="inputfield w-4" :disabled="disableAddlInputs" /> 
      </div>
    </div>
    <div class="grid">
      <div class="col-2">    
        <label for="enddate" class="date">End Date:</label> 
      </div>
      <div class="col-10">
        <InputText id="startdate" type="date" v-model="enddate" class="inputfield w-4" :disabled="disableAddlInputs" /> 
      </div>
    </div>
  </Fieldset>

  <br/>

  <Fieldset legend="Pledge Level Specifics">
    <div class="grid">
      <div class="col-2">    
        <label for="creator" class="required">Level:</label>
      </div>
      <div class="col-10">
         <InputText id="level" type="text" v-model="level" class="inputfield w-5" /> 
      </div>
    </div>
    <div class="grid">
      <div class="col-2">    
        <label for="creator">Description:</label>
      </div>
      <div class="col-10">
         <Textarea v-model="description" rows="5" class="inputfield w-10" />
      </div>
    </div>
    <div class="grid">
      <div class="col-2">    
        <label for="amount">Amount:</label>
      </div>
      <div class="col-10">
         <div class="p-inputgroup w-3">
            <span class="p-inputgroup-addon">$</span>
            <InputText type="number" v-model="amount" class="inputfield" />
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-2">    
        <label for="delivery" class="date">Est. Delivery:</label> 
      </div>
      <div class="col-10">
        <InputText id="delivery" type="date" v-model="delivery" class="inputfield w-4" /> 
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <Divider type="dashed" />
      </div>
    </div>
    <div class="grid">
      <div class="col-2">    
        <label for="status">Status:</label>
      </div>
      <div class="col-10">
        <Dropdown v-model="status" :options="statusOptionsDD" optionValue="code" optionLabel="label" class="inputfield w-4" />
      </div>
    </div>
    <div class="grid">
      <div class="col-2">    
        <label for="health">You're feeling:</label>
      </div>
      <div class="col-10">
        <Dropdown v-model="health" :options="healthOptionsDD" optionValue="code" optionLabel="label" class="inputfield w-4" />
      </div>
    </div>
    <div class="grid">
      <div class="col-2">    
        <label for="creator">Notes:</label>
      </div>
      <div class="col-10">
         <Textarea v-model="note" rows="5" class="inputfield w-10" />
      </div>
    </div>
    <br/><br/>
    <div class="grid">
      <div class="col-offset-2 col-10">
        <Button icon="pi pi-save" @click="createPledge" :loading="isLoading" label="Save Pledge" class="primary-button inputfield" />
        <a @click="resetFormFields" class="reset-link">Reset Form?</a>
      </div>
    </div>
  </Fieldset>
</template>

<style lang="scss">
label {
  margin-top: 0.75em;
  display: block;
  &.date {
    margin-top: .75em;
  }
  &.required {
    font-weight: bold;
  }
}
.success {
  color: green;
}
.error {
  color: red;
}
.pi {
  font-size: 1.25em;
  vertical-align: middle;
}
.reset-link {
  color: var(--text-color-secondary); 
  margin-bottom: 1em;
  display: inline-block;
  margin-left: 1em; 
  cursor: pointer;
}
</style>