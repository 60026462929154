<script>
import { ref, watch } from 'vue';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import { usePledgesStore } from '@/stores/pledges.store';

export default {
  props: {

  },
  components: {
    TriStateCheckbox
  },
  emits: [],
  setup() {
    const pledgesStore = usePledgesStore();
    const showAllPledges = ref(null);

    showAllPledges.value = pledgesStore.stateListLevel;

    watch(
      () => showAllPledges.value,
      (newVal) => {
        pledgesStore.setPledgesListLevel(newVal);
      }
    );

    return {
      showAllPledges,
    }
  }
}
</script>

<template>
  <div class="field-checkbox listing-checkbox">
    <TriStateCheckbox v-model="showAllPledges" id="showAllPledges" :binary="true" />
    <label class="hide-delivered" for="showAllPledges">{{ showAllPledges === true ? 'Showing All Pledges' : showAllPledges === false ? 'Showing Delivered Pledges' : 'Showing Open Pledges' }}</label>
  </div>
</template>

<style lang="scss" scoped>
.listing-checkbox {
  float: right; 
  margin-bottom: 0; 
  margin-top: -.5em;
}

.hide-delivered {
  cursor: pointer;
}
::v-deep(.p-checkbox-box) {
  margin-top: 0.35em;
}
</style>