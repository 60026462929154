<script>
import { reactive, ref, watch } from 'vue';
import { useToast } from 'primevue/usetoast';
import { usePledgesStore } from '@/stores/pledges.store';
import Divider from 'primevue/divider';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import { formatDateShort, formatDateNormal } from '@/utility/dateFormatting';
import { stringToRgbColor } from '../../utility/stringToColor';
import formatCurrency from '@/utility/formatCurrency';

export default {
  props: {
    pledge: Object,
    mode: String,
  },
  components: {
    Chip,
    Chips,
    Divider,
    InputText,
    TextArea,
  },
  emits: ['cancel'],
  setup (props) {
    const toast = useToast();
    const pledgesStore = usePledgesStore();
    const activePledge = reactive({ ...props.pledge });
    const editPledge = ref(props.mode === 'details' ? false : true);

    const savePledge = async () => {
      try {       
        await pledgesStore.editUserPledge({
          ...activePledge,
          id: activePledge._id,
          formattedDelivery: activePledge.formattedDelivery === '???' ? '' : activePledge.formattedDelivery,
          tags: activePledge.tags.join(', '),
        });
        toast.add({severity:'success', summary: 'Updated!', detail: 'The pledge details have been updated.', life: 3000});
      } catch(e) {
        toast.add({severity:'danger', summary: 'Error!', detail: 'There was an error updating the pledge.', life: 3000});
      }
    };

    const refreshCampaign = async () => {
      pledgesStore.refreshCampaignImage(activePledge.url).then(({ data }) => {
        pledgesStore.getUserPledges();
        activePledge.image = data.image;
      });
    }

    watch(
      () => props.mode,
      (newMode) => {
        editPledge.value = newMode === 'details' ? false : true;
      }
    );

    return {
      activePledge,
      editPledge,
      formatDateShort, 
      formatDateNormal,
      formatCurrency,
      stringToRgbColor,
      savePledge,
      refreshCampaign,
    }
  }
}
</script>

<template>
  <div v-if="!editPledge">
    <div style="position: relative;">
      <Button icon="pi pi-sync" class="p-button-secondary p-button-text text-500 refresh-button" @click="refreshCampaign()"/>
      <img v-if="activePledge.image" :src="activePledge.image" class="campaignImg"/>
    </div>
    <div class="font-semibold">{{ activePledge.level }}</div>
    <br/>
    <div v-if="activePledge.description.length > 0" class="description border-round" v-html="activePledge.description.replaceAll('\n', '<br />')"></div>
    <br />
    <div>
      Pledge Amount: <Chip :label="formatCurrency(activePledge.amount)" class="mr-2 mb-2 amount" style="font-size: .9em;" /> 
      <span v-if="activePledge.pledgemanaged">✅</span>
      &nbsp;| &nbsp; 
      Est. Delivery: {{ formatDateShort(activePledge.formattedDelivery) }}
      <br/>
      <Chip v-for="tag in activePledge.tags" :label="tag" :key="tag" class="mr-2 mb-2 tag" :style="{ 'font-size': '.8em', 'border': `${stringToRgbColor(tag, '1')} solid 1px`, color: stringToRgbColor(tag, '1') }" />
    </div>
    <div v-if="activePledge.notes?.length > 0">
      <Divider type="dashed" />
      <span class="font-medium" v-badge.info>Notes:</span><br/><br/>
      {{ activePledge.notes }}
    </div>
  </div>
  <div v-else>
    <div class="separator"></div>
    <span class="p-float-label">
      <InputText id="editLevel" type="text" v-model="activePledge.level" class="p-inputtext-sm" style="width: 100%;" />
      <label for="editLevel">Level:</label>
    </span>
    <div class="separator"></div>
    <span class="p-float-label">
      <TextArea id="editDescription" type="text" rows="4" v-model="activePledge.description" class="p-inputtext-sm" style="width: 100%;" />
      <label for="editDescription">Description:</label>
    </span>
    <div class="separator"></div>
    <div class="grid">
      <div class="col-6">
        <div class="p-inputgroup w-3">
          <span class="p-inputgroup-addon">$</span>
          <InputText id="editAmount" type="number" v-model="activePledge.amount" class="inputfield p-inputtext-sm" style="width: 100px;"/>
        </div>
      </div>
      <div class="col-6">
        <span class="p-float-label">
          <InputText id="editDelivery" v-model="activePledge.formattedDelivery" class="inputfield p-inputtext-sm w-8" />
          <label for="editDelivery">Est. Delivery:</label>
        </span>
      </div>
    </div>
    <div class="separator"></div>
    <span class="p-float-label">
      <TextArea id="editNote" type="text" rows="4" v-model="activePledge.notes" class="p-inputtext-sm w-full" />
      <label for="editNote">Your Notes:</label>
    </span>
    <div class="separator"></div>
    <span class="p-float-label">
      <Chips v-model="activePledge.tags" separator="," class="w-full" />
      <label for="editLevel">Tags:</label>
    </span>
  </div>
  <br/>
  <div style="text-align: right;">
    <Button v-if="editPledge" class="p-button-secondary p-button-text text-500" label="Cancel" @click="$emit('cancel')" />
    <Button v-if="!editPledge" icon="pi pi-pencil" class="p-button-secondary p-button-text text-500" label="Edit Details" @click="editPledge = true" />
    <Button v-else icon="pi pi-save" class="primary-button p-button-primary" label="Save Changes" @click="savePledge" />
  </div>
</template>

<style lang="scss" scoped>
.description {
  padding: 1em;
  background-color: #f7f7f7;
  max-height: 200px;
  overflow: auto;
}

.campaignImg {
  width: 100%;
  margin-bottom: 1em;
}

.refresh-button {
  position: absolute;
  right: 5px;
  bottom: 20px;
}
</style>