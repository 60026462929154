<script>
export default {
  setup () {
    

    return {}
  }
}
</script>

<template>
  <div>
    You aren't tracking any pledges yet!
  </div>
</template>

<style lang="scss" scoped>

</style>