import { createRouter, createWebHistory } from 'vue-router';
import HomeIndex from '@/listing/views/index';
import About from '@/about/views/about';
import Profile from '@/profile/views/profile';

const routes = [
    { path: '/', name: 'index-view', component: HomeIndex },
    { path: '/about', name: 'about-view', component: About },
    { path: '/users/:username', name: 'profile-view', component: Profile },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;