<script>
import { computed } from 'vue';
import { useUserStore } from '@/stores/user.store';
import Listing from './listing.vue';
import Welcome from './welcome.vue';

export default {
  name: 'index-view',
  components: {
    Listing,
    Welcome,
  },
  setup() {
    const userStore = useUserStore();
    const isLoggedIn = computed(() => userStore.isLoggedIn);    

    return {
      isLoggedIn,
    }
  }
}
</script>

<template>
  <Listing v-if="isLoggedIn" />
  <Welcome v-else />
</template>

<style lang="scss" scoped>

</style>