import axios from 'axios';

export function jwtInterceptor(jwtToken) {
  axios.interceptors.request.use(request => {
    const isApiUrl = request.url.startsWith(`${process.env.VUE_APP_HOST}/api`);

    if (jwtToken && isApiUrl) {
      request.headers.common.Authorization = `Bearer ${jwtToken}`;
    }

    return request;
  });
}

export function clearJwtInterceptor() {
  axios.interceptors.request.use(request => {
    delete request.headers.common.Authorization;
    return request;
  });
}