<script>
import { useUserStore } from '@/stores/user.store';
import { flattenedPledges, usePledgesStore } from '@/stores/pledges.store';
import { useRoute } from 'vue-router'
import { onMounted, ref, reactive } from 'vue';
import { useToast } from 'primevue/usetoast';
import Divider from 'primevue/divider';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Message from 'primevue/message';
import { statusOptions, healthOptions } from '@/constants';
import { find, debounce } from 'lodash';
import dayjs from 'dayjs';
import grid from '../../listing/components/grid.vue';

const alphanumeric = (inputtxt) => {
 var letterNumber = /^[0-9a-zA-Z]+$/;
 if (inputtxt.match(letterNumber)) {
   return true;
  } else { 
   return false; 
  }
}

export default {
  components: {
    grid,
    Divider,
    Dialog,
    Dropdown,
    InputText,
    TextArea,
    Message,
  },
  setup() {
    const toast = useToast();
    const route = useRoute()
    const userStore = useUserStore();
    const pledgesStore = usePledgesStore();
    const editProfile = ref(false);
    const user = reactive({});
    const pledges = reactive([]);
    const usernameStatus = reactive({
      checking: false,
      valid: true,
    });
    const editProfileDetails = reactive({});
    const socialTypeOptions = reactive([
      { type: 'Twitter', label: 'Twitter' },
      { type: 'Facebook', label: 'Facebook' },
      { type: 'YouTube', label: 'YouTube' },
      { type: 'Instagram', label: 'Instagram' },
      { type: 'Reddit', label: 'Reddit' },
      { type: 'Discord', label: 'Discord' },
      { type: 'Slack', label: 'Slack' },
    ]);

    const fetchPublicProfile = async () => {
      // public stuff:
      const profile = await userStore.getUserPublicProfile(route.params.username);
      Object.assign(user, profile);
      const fetchedPledges = await pledgesStore.getUserPledgesByUsername(route.params.username);
      pledges.push(...flattenedPledges({ pledges: fetchedPledges.data }));

      if (userStore.isLoggedIn) {
        Object.assign(editProfileDetails, {...userStore.user});
      }
    };

    onMounted(async () => {
      await fetchPublicProfile();
    });

    const lookupStatusIcon = (val) => find(statusOptions, { code: val })?.icon;
    const lookupHealthIcon = (val) => find(healthOptions, { code: val })?.icon;
    const formatDeliveryDate = (dateStr) => {
      return dateStr ? dayjs(dateStr).format('MMM, YYYY') : '???';
    };

    const saveProfile = async () => {
      if (usernameStatus.valid && editProfileDetails.username.length > 0) {
        try {
          await userStore.saveProfile(editProfileDetails);
          toast.add({severity:'success', summary: 'Success!', detail: 'Profile updated successfully.', life: 3000});
          Object.assign(user, editProfileDetails);
        } catch {
          toast.add({severity:'error', summary: 'Error', detail: 'There was an error updating your profile.', life: 3000});
        }
      } else {
        toast.add({severity:'error', summary: 'Error', detail: 'Please fix errors before saving.', life: 3000});
      }
    };

    const usernameLookup = debounce(() => {
      usernameStatus.checking = true;
      usernameStatus.valid = false;
      usernameStatus.error = false;
      usernameStatus.invalidCharacters = false;
      if (!alphanumeric(editProfileDetails.username)) {
        usernameStatus.checking = false;
        usernameStatus.valid = false;
        usernameStatus.invalidCharacters = true;
        return;
      }
      userStore.getUserPublicProfile(editProfileDetails.username)
        .then((response) => {
          if (!response) {
            usernameStatus.valid = true;
          } else if (editProfileDetails.username === user.username) {
            usernameStatus.valid = true;
          } else {
            usernameStatus.valid = false;
          }
        }).catch((e) => {
          usernameStatus.valid = false;
        })
        .finally(() => {
          usernameStatus.checking = false;
        }); 
    }, 350);

    const copyProfileUrl = () => {
      navigator.clipboard.writeText(`https://bactrac.herokuapp.com/users/${route.params.username}`);
      toast.add({severity:'success', summary: 'Url Copied!', detail: 'The profile url has been added to your clipboard.', life: 3000});
    }

    const removeSocialLink = (url) => {
      editProfileDetails.social_links = [...editProfileDetails.social_links.filter((link) => link.url.toLowerCase() !== url.toLowerCase() )];
    }

    const addSocialType = ref('');
    const addSocialLink = ref('');
    const saveSocialLink = () => {
      editProfileDetails.social_links.push({
        type: addSocialType.value.toLowerCase(),
        url: addSocialLink.value.toLowerCase(),
      });
      addSocialType.value = '';
      addSocialLink.value = '';
    }

    const gotoSocial = (url) => {
      window.open(url);
    }

    return {
      user,
      userStore,
      pledges,
      editProfile,
      editProfileDetails,
      lookupStatusIcon,
      lookupHealthIcon,
      formatDeliveryDate,
      saveProfile,
      copyProfileUrl,
      usernameStatus,
      usernameLookup,
      socialTypeOptions,
      addSocialType,
      addSocialLink,
      saveSocialLink,
      removeSocialLink,
      gotoSocial,
    }
  }
}
</script>

<template>

  <Message severity="info" v-if="~user.username?.indexOf('@')">
    <b>Your username is currently your email address.</b><br/>
    Please edit your profile to provide a new, custom, username for yourself!
  </Message>

  <Button v-if="userStore.isLoggedIn && userStore.user.username === user.username" @click="editProfile = true" style="float: right; margin-top: 1em;" class="p-button-text p-button-secondary" label="Edit Profile" icon="pi pi-id-card" /> 

  <div v-if="user.avatar?.length > 0" :style="{ 'background-image': `url(${user.avatar})` }" class="avatar"></div>

  <h2>{{ user.username }} Backed Projects! 
  <a :href="`https://bactrac.herokuapp.com/users/${user.username}`" @click.stop.prevent="copyProfileUrl"><i class="pi pi-copy clipboard"></i></a></h2>
  <p>
    {{ user.bio }}
  </p>
  <Button v-for="link in user.social_links" :key="link.url" :icon="`pi pi-${link.type}`" class="social-icon p-button-rounded p-button-outlined p-button-secondary" @click="gotoSocial(link.url)" />

  <Divider type="dashed" />
  <grid :pledges="pledges" mode="public" />

  <Dialog header="Update Your Profile" v-model:visible="editProfile" :style="{width: '40vw'}" :modal="true">
    <div class="separator"></div>
    <span class="p-float-label">
      <InputText id="editUsername" type="text" v-model="editProfileDetails.username" :class="{'p-inputtext-sm': true, 'p-invalid': false }" style="width: 40%;" @keyup="usernameLookup"/> &nbsp;
      <label for="editLevel">Username:</label>
      <i v-if="usernameStatus.checking" class="pi pi-spin pi-sync"></i>
      <i v-else-if="!usernameStatus.valid || usernameStatus.invalidCharacters" class="pi pi-exclamation-triangle" style="color: #ffaf66;"></i>
      <i v-else class="pi pi-check-circle" style="color: green;"></i>
      <br/>
      <small v-if="!usernameStatus.valid && !usernameStatus.invalidCharacters && !usernameStatus.checking" class="p-error">Username is not available.</small>
      <small v-else-if="usernameStatus.invalidCharacters" class="p-error">Username must contain only letters and numbers.</small>
    </span>
    <div class="separator"></div>
    <span class="p-float-label">
      <TextArea id="editBio" type="text" rows="4" v-model="editProfileDetails.bio" class="p-inputtext-sm" style="width: 100%;" />
      <label for="editBio">About/Bio:</label>
    </span>
    <div class="separator"></div>
    <span class="p-float-label">
      <InputText id="editAvatar" type="text" v-model="editProfileDetails.avatar" class="p-inputtext-sm" style="width: 100%;" />
      <label for="editAvatar">Avatar Url:</label>
    </span>
    <div class="separator"></div>
     <span class="p-float-label">
      <span class="label">Social Media Links:</span><br/>


      <div class="grid">
        <div class="col-10">
          <Dropdown id="editSocialMediaLinks" v-model="addSocialType" :options="socialTypeOptions" placeholder="Select..." optionValue="type" optionLabel="label" class="p-inputtext-sm inputfield w-3 socialDD">
            <template #value="slotProps">
              <div v-if="slotProps.value">             
                <i :class="['pi', `pi-${slotProps.value.toLowerCase()}`]"></i> {{ slotProps.value }}
              </div>
            </template>
            <template #option="slotProps">
              <i :class="['pi', `pi-${slotProps.option.type.toLowerCase()}`]"></i> {{ slotProps.option.label }}
            </template>
          </Dropdown> &nbsp; 
          <InputText id="editAvatar" type="text" v-model="addSocialLink" placeholder="https://..." class="p-inputtext-sm" style="width: 70%;" />
        </div>
        <div class="col-2">
          <Button icon="pi pi-plus-circle" class="p-button-outlined p-button-primary p-button-sm" label="Add" @click="saveSocialLink()" />
        </div>
      </div>
      
      <div v-for="link in editProfileDetails.social_links" :key="link.url">
        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text social-delete" @click="removeSocialLink(link.url)"/>
        
        <div style="padding: 1em;">
        <i :class="['pi', `pi-${link.type.toLowerCase()}`]"></i> - <a :href="link.url" target="_blank">{{ link.url }}</a>
        </div>
      </div>

    
    </span>

    <template #footer>
      <Button icon="pi pi-save" class="primary-button p-button-primary" label="Save Profile" @click="saveProfile" />
    </template>
  </Dialog>
</template>

<style lang="scss" scoped>
.avatar {
  float: left;
  width: 90px;
  height: 90px;
  border-radius: 90px;
  margin: 1em;
  margin-top: 2em;
  background-size: 160px;
  background-position: center;
  background-repeat: no-repeat;
}

.social-icon {
  margin-right: .5em;
}

.clipboard {
  cursor: pointer;
  font-size: 18px;
}

.label {
  top: -0.75rem;
  font-size: 12px;
  padding: 0.75rem;
  line-height: 2rem;
  color: #71717A;
}

.p-button-sm {
  padding: 8px;
  color: var(--green-500) !important;
  border: solid 1px var(--green-500) !important;
}

.social-delete {
  float: right;
  padding: 2px;
}
</style>