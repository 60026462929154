import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router';
import { globalCookiesConfig } from "vue3-cookies";
import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Tooltip from 'primevue/tooltip';
import BadgeDirective from 'primevue/badgedirective';

import 'primevue/resources/themes/tailwind-light/theme.css';
// import '@/_theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

globalCookiesConfig({
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: false,
  sameSite: "None",
});

const app = createApp(App);

app.component('Button', Button);
app.component('Toast', Toast);
app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);
app.use(PrimeVue);
app.use(ToastService);
app.use(router);
app.use(createPinia());

app.mount('#app');
