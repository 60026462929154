export const statusOptions = [
  { code: 0, icon: '💭', label: 'Open' },
  { code: 1, icon: '🕝', label: 'Production' },
  { code: 2, icon: '🚚', label: 'Awaiting Delivery' },
  { code: 3, icon: '⏰', label: 'Behind Schedule' },
  { code: 4, icon: '📦', label: 'Delivered' },
  { code: 5, icon: '⛔️', label: 'Canceled' },
  { code: 6, icon: '❌', label: 'Unfulfilled' },
];
export const healthOptions = [
  { code: 1, icon: '🤩', label: 'Excited' },
  { code: 2, icon: '😀', label: 'Happy' },
  { code: 3, icon: '😏', label: 'Intrigued' },
  { code: 0, icon: '😶', label: 'Neutral' },
  { code: 4, icon: '🧐', label: 'Concerned' },
  { code: 5, icon: '😖', label: 'Frustrated' },
  { code: 6, icon: '😡', label: 'Angry' },
];
export const providerOptions = [
  { name: 'Kickstarter', code: 'kickstarter' },
  { name: 'Gamefound', code: 'gamefound' },
  { name: 'IndieGoGo', code: 'indiegogo' },
];