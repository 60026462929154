import dayjs from 'dayjs';

export const formatDateShort = (dateStr) => {
  return dateStr ? dayjs(dateStr).format('MMM, YYYY') : '???';
}
export const formatDateShortDay = (dateStr) => {
  return dateStr ? dayjs(dateStr).format('MMM DD, YYYY') : '???';
}
export const formatDateNormal = (dateStr) => {
  return dateStr ? dayjs(dateStr).format('MM/DD/YYYY') : '???';
}